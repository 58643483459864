<template>
    <div class="container footer">
      <div class="footer-warning">
        <div>声明：本站产品均为商家辅助使用</div>
        <div>禁止使用于违法或侵害他人权益行为，若有立马封号处理，余额不退还，情节严重者，本站积极协助公安机关调查！</div>
      </div>
      <div class="footer-link">
        <div>Copyright © 2021 {{$store.getters.getSiteInfo.name}} 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index">{{$store.getters.getSiteInfo.beian}}</a></div>
      </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped src="styles/components/bottomfooter.css">
</style>
