<template>
  <div>
    <div class="container-full banner-content">
      <el-carousel class="carousel" height="560px" arrow="hover" :interval="5000" trigger="click">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.localimg" @click="bannerClick(index)">
        </el-carousel-item>
      </el-carousel>
      <div class="banner-usermenu">
        <index-banner-item/>
      </div>
    </div>
  </div>
</template>

<script>
import IndexBannerItem from 'views/index/childComps/IndexBannerItem'
import { getAdsInfo } from 'network/api'

export default {
  components: { IndexBannerItem },
  data () {
    return {
      bannerList: []
    }
  },
  created () {
    getAdsInfo({ adstag: 'ads', appkey: this.$appkey }).then(res => {
      this.bannerList = res.data.adslist
    })
  },
  methods: {
    bannerClick (index) {
      switch (index) {
        case 0:
          window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
          break
        case 1:
          this.$router.push('/join')
          break
        case 2:
          this.$router.push('/account/InviteFriends')
          break
      }
    }
  }
}
</script>

<style scoped src="styles/views/index/indexbanner.css">
</style>
