<template>
  <div class="container-full hot-goods">
    <div class="container">
      <hot-goods-title/>
      <div class="goods-content">
        <div class="goods-content-left">
          <div class="goods-content-left-t">
            <img class="goods-content-left-t-img" src="~assets/images/index/baokuan.png">
            <div class="goods-content-left-t-title">{{leftGoods.goods_name}}</div>
            <div class="goods-content-left-t-price">￥{{leftGoods.goods_price}}</div>
            <div @click="toMall" class="goods-content-left-t-btn">立即选购</div>
          </div>
          <div class="goods-content-left-b">
            <img @click="toMall" class="goods-content-left-b-img" :src="leftGoods.goods_images">
            <div class="goods-content-left-b-desc">
              <div class="name">{{leftGoods.storage_name}}</div>
              <div class="site">{{leftGoods.goods_weight}}g/件</div>
            </div>
          </div>
        </div>
        <div class="goods-content-right">
          <div class="card-list" v-for="(item, index) in goods" :key="index">
            <hot-goods-card :goodsItem="item"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotGoodsTitle from 'views/index/childComps/HotGoodsTitle'
import HotGoodsCard from 'views/index/childComps/HotGoodsCard'
import { getIndexGoods } from 'network/api'
export default {
  components: {
    HotGoodsTitle,
    HotGoodsCard
  },
  data () {
    return {
      leftGoods: {},
      goods: []
    }
  },
  created () {
    getIndexGoods({ appkey: this.$appkey }).then(res => {
      this.leftGoods = res.data[0]
      this.goods = res.data.splice(1, 8)
    })
  },
  methods: {
    toMall () {
      this.$router.push('/orderAdmin/purchase')
    }
  }
}
</script>

<style scoped src="styles/views/index/hotgoods.css">
</style>
