<template>
  <div class="invite">
    <div class="container invite-content">
      <div class="title">邀请好友，赚佣金</div>
      <step/>
      <div @click="inviteNow" class="btn">
        <span>立即邀请好友</span>
        <img src="~assets/icons/rmb.png">
      </div>
    </div>
  </div>
</template>

<script>
import Step from 'components/step/Step'
export default {
  components: {
    Step
  },
  data () {
    return {
    }
  },
  methods: {
    inviteNow () {
      this.$router.push('/account/InviteFriends')
    }
  }
}
</script>

<style scoped src="styles/views/index/invite.css">
</style>
