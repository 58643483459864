<template>
  <div class="title">
    <div class="title-left">
      <span class="rxbd">热销榜单</span>
      <span class="wmjl">物美价廉，众多商家共同的选择~</span>
    </div>
    <div class="title-right">
      <el-button type="text" @click="moreGoods">更多礼品<i class="el-icon-arrow-right"></i></el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    moreGoods () {
      this.$router.push('/orderAdmin/purchase')
    }
  }
}
</script>

<style scoped src="styles/views/index/hotgoodstitle.css">
</style>
