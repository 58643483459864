<template>
  <div class="step">
    <div class="step-num">
      <img :src="leftUrl">
      <div :class="fontStyle" class="step-num-title">{{leftTitle}}</div>
    </div>
    <img class="step-arrow" :src="arrow">
    <div class="step-num">
      <img :src="centerUrl">
      <div :class="fontStyle" class="step-num-title">{{centerTitle}}</div>
    </div>
    <img class="step-arrow" :src="arrow">
    <div class="step-num">
      <img :src="rightUrl">
      <div :class="fontStyle" class="step-num-title">{{rightTitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftUrl: { default: require('assets/images/index/step1.png') },
    leftTitle: { default: '分享邀请链接给好友' },
    centerUrl: { default: require('assets/images/index/step2.png') },
    centerTitle: { default: '好友注册并成功下单' },
    rightUrl: { default: require('assets/images/index/step3.png') },
    rightTitle: { default: '佣金到账，马上提现（0.05元/单）' },
    fontStyle: { default: 'font-White' },
    arrow: { default: require('assets/images/index/steparrow.png') }
  }
}
</script>

<style scoped src="styles/components/step.css">
</style>
