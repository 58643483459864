<template>
  <div>
    <el-card class="goods-card" @click.native="toMall" :body-style="{ padding: '15px' }">
      <img :src="goodsItem.goods_images[0]" class="image">
      <div class="flex flex-t">
        <div class="price">￥{{goodsItem.goods_price}}</div>
        <div>{{goodsItem.goods_weight}}g/件</div>
      </div>
      <div class="flex flex-b">
        <div>{{goodsItem.goods_name}}</div>
        <div>{{goodsItem.goods_storage}}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    goodsItem: Object
  },
  methods: {
    // 点击每个商品跳转
    toMall () {
      this.$router.push('/orderAdmin/purchase')
    }
  }
}
</script>

<style scoped src="styles/views/index/hotgoodscard.css">
</style>
